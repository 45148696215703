.navbar {
    background: rgb(0, 0, 0);
    height: 80px;
    display: flex;
    align-items: center;
    font-size: 1.2rem;
}

.navbar-container {
    display: flex;
    justify-content: space-around;
    width: 100%;
}

.navbar-logo {
    color: #fff;
    font-size: 2rem;
    display: flex;
    align-items: center;
}

.navbar-icon {
    padding: 0.5rem;
}

.nav-menu {
    display: flex;
    list-style-type: none;
}

.nav-item {
    height: 80px;
    cursor: pointer;
    border-bottom: 2px transparent;
}

.nav-item:hover {
    border-bottom: 2px solid #f00946;
}

.nav-links {
    color: #fff;
    display: flex;
    align-items: center;
    margin: 1rem;
    height: 80%;
}

.fa-bars {
    color: #fff;
}

.menu-icon {
    display: none;
}

@media screen and (max-width: 960px) {
    .nav-menu {
        display: none;
    }

    .nav-menu.active {
        background: #011F44;
        right: 0;
        width: 100%;
        height: 30vh;
        display: flex;
        flex-direction: column;
        position: absolute;
        z-index: 999;
        top: 8vh;
    }

    .nav-links {
        justify-content: center;
    }

    .nav-item:hover {
        border: none;
    }

    .menu-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 2rem;
        cursor: pointer;
    }

    .fa-times {
        color: #fff;
        font-size: 2rem;
    }
}
