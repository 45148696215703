@import '../Constants';

.about {
    position: relative;
    background-color: $primary-color;

    &__container {
        display: flex;
        padding-bottom: 10rem;
        justify-content: space-evenly;
    }

    &__left {
        width: 30%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__right {
        width: 30%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        align-items: center;
    }

    &__text {
        font-size: $size-l;
        color: white;
    }

    img {
        width: 100%;
        border-radius: 50%;
        box-shadow: 0 0 10px 5px black;
    }

    a {
        text-decoration: none;
        color: white;
        background-color: black;
        padding: $size-xs $size-s;
        border-radius: $size-s;
        white-space: nowrap;
        margin: $size-s;
        height: 5rem;
        width: 15rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: $size-l;

        &:hover {
            background-color: #fff;
            color: rgb(0, 0, 0);
            transition: all 0.5s ease;
        }
    }
}

.svg_curve_top {
    width: 100%;
    height: 10rem;
    position: relative;
    transform: translateY(-99%);
}

@media screen and (max-width: $screen-lg) {

    .about {
        &__container {
            flex-direction: column;
            align-items: center;
        }

        &__right {
            align-items: center;
        }

        img {
            width: 100%;
        }
    }

    .svg_curve_top {
        width: 100%;
        height: $size-l;
        position: relative;
        transform: translateY(-99%);
    }
}
