@import '../Constants';

.satellite {
    background-image: url("../assets/images/satellite.jpg");
    background-size: cover;
    height: 100vh;

    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 80%;
    }
}

.typewriter {
    position: relative;

    span {
        text-transform: uppercase;
        color: black;
        padding: $size-s;
        font-size: $size-xxl;
        font-weight: bold;
        letter-spacing: $size-xs;
        text-align: center;
    }
}

.carousel {
    position: relative;
    overflow: hidden;
    
    font-size: 35px;
    line-height: 40px;
    color: black;
    
    &__container {
        font-weight: 600;
        overflow: hidden;
        height: 40px;
        padding: 0 40px;
    
        &:before {
            content: '[';
            left: 0;
        }
    
        &:after {
            content: ']';
            position: absolute;
            right: 0;
        }
    
        &:after, &:before {
            position: absolute;
            top: 0;
            
            color: #011F44;
            font-size: 42px;
            line-height: 40px;
            
            -webkit-animation-name: opacity;
            -webkit-animation-duration: 2s;
            -webkit-animation-iteration-count: infinite;
            animation-name: opacity;
            animation-duration: 2s;
            animation-iteration-count: infinite;
        }
    
        &__text {
            display: inline;
            float: left;
            margin: 0;
        }
    
        &__list {
            margin-top: 0;
            padding-left: 110px;
            text-align: left;
            list-style: none;
            
            -webkit-animation-name: change;
            -webkit-animation-duration: 10s;
            -webkit-animation-iteration-count: infinite;
            animation-name: change;
            animation-duration: 10s;
            animation-iteration-count: infinite;
    
            &__item {
                line-height:40px;
                margin:0;
            }
        }
    }
}

@-webkit-keyframes opacity {
    0%, 100% {opacity:0;}
    50% {opacity:1;}
}

@-webkit-keyframes change {
    0%, 12.66%, 100% {transform:translate3d(0,0,0);}
    16.66%, 29.32% {transform:translate3d(0,-25%,0);}
    33.32%,45.98% {transform:translate3d(0,-50%,0);}
    49.98%,62.64% {transform:translate3d(0,-75%,0);}
    66.64%,79.3% {transform:translate3d(0,-50%,0);}
    83.3%,95.96% {transform:translate3d(0,-25%,0);}
}

@-o-keyframes opacity {
    0%, 100% {opacity:0;}
    50% {opacity:1;}
}

@-o-keyframes change {
    0%, 12.66%, 100% {transform:translate3d(0,0,0);}
    16.66%, 29.32% {transform:translate3d(0,-25%,0);}
    33.32%,45.98% {transform:translate3d(0,-50%,0);}
    49.98%,62.64% {transform:translate3d(0,-75%,0);}
    66.64%,79.3% {transform:translate3d(0,-50%,0);}
    83.3%,95.96% {transform:translate3d(0,-25%,0);}
}

@-moz-keyframes opacity {
    0%, 100% {opacity:0;}
    50% {opacity:1;}
}

@-moz-keyframes change {
    0%, 12.66%, 100% {transform:translate3d(0,0,0);}
    16.66%, 29.32% {transform:translate3d(0,-25%,0);}
    33.32%,45.98% {transform:translate3d(0,-50%,0);}
    49.98%,62.64% {transform:translate3d(0,-75%,0);}
    66.64%,79.3% {transform:translate3d(0,-50%,0);}
    83.3%,95.96% {transform:translate3d(0,-25%,0);}
}

@keyframes opacity {
    0%, 100% {opacity:0;}
    50% {opacity:1;}
}

@keyframes change {
    0%, 12.66%, 100% {transform:translate3d(0,0,0);}
    16.66%, 29.32% {transform:translate3d(0,-25%,0);}
    33.32%,45.98% {transform:translate3d(0,-50%,0);}
    49.98%,62.64% {transform:translate3d(0,-75%,0);}
    66.64%,79.3% {transform:translate3d(0,-50%,0);}
    83.3%,95.96% {transform:translate3d(0,-25%,0);}
}

@media screen and (max-width: $screen-lg) {
    .typewriter {
        max-width: 90%;
    }

    .carousel {
        display: none;
    }
}
