@import '../Constants';

.projects {
    background-image: url("../assets/images/cartoonspace_noearth.png");
    background-size: cover;
    background-position: 0 500%;
    height: 100vh;

    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: absolute;
        height: 100vh;
        width: 100%;

        h1 {
            color: white;
            font-size: $size-xl;
        }
    }

    &__tabs {
        margin: $size-xl;
        background-color: white;
        max-width: 60vw;
    }
}

.card {
    position: relative;
    width: 50vw;
    height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__media {
        height: auto; 
        width: auto;
        max-width: 20vh; 
        max-height: 20vw;
        padding: $size-xs;
    }

    &__link-button {
        cursor: pointer;
    }
}

.project {
    &__name {
        font-size: $size-l;
    }

    &__skills {
        font-size: $size-m;
    }

    &__description {
        font-size: $size-s;
    }
}
