$primary-color: #011F44;

$font-stack: Source Sans Pro;

$size-xxl: 4rem;
$size-xl: 3rem;
$size-l: 2rem;
$size-m: 1.5rem;
$size-s: 1rem;
$size-xs: .5rem;

$screen-lg: 1200px;
